import React from "react";
import BaseLayout from "../components/BaseLayout";
import SEO from "../components/seo";

const NotFoundPage = () => {
  const isBrowser = () => typeof window !== "undefined";
  isBrowser() && window.location.assign("https://www.pfresolu.com/Carrieres/");
  return (
    <BaseLayout>
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </BaseLayout>
  );
};

export default NotFoundPage;
